$theme: "light";

@import '../shared/compass-support';
@import '../shared/mixins';
@import '../shared/icons';

@import 'variables/custom';
@import 'variables/bootstrap';
@import 'variables/charts';

@import '../shared/bootstrap-components';

@import 'theme-shared/modules/animations';
@import 'theme-shared/modules/forms';
@import 'theme-shared/modules/tables';
@import 'theme-shared/modules/buttons';
@import 'theme-shared/modules/energomonitor';
@import 'theme-shared/modules/settings';
@import 'theme-shared/modules/saving_center';
@import 'theme-shared/modules/datepicker';
@import 'theme-shared/modules/charts';
@import 'theme-shared/modules/widgets';
@import 'theme-shared/modules/notifications';
@import 'theme-shared/modules/registration';

@import 'web_rebranded/overrides';
